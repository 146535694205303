import React from "react";

import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Stack, Typography } from "@mui/material";
import { X } from "@phosphor-icons/react";

import { styles } from "./styles";
import { FootballCardProps } from "./types";

const FOOTBALL_URL = "https://www.abasteceai-futebol.com.br/";

/**
 * Get club logo or defaul shield if logo not exists
 */
const getClubLogo = (logo: string, defaultShield?: string) => {
  if ((!logo || logo.includes("logo_visitante")) && defaultShield) {
    return defaultShield;
  }

  return logo;
};

export const FootballCard: React.FC<FootballCardProps> = ({
  clubLogo,
  club,
  valueWithoutPoints,
  valueWithPoints,
  clubOpponent,
  matchDate,
  clubOpponentLogo,
  tags,
  description,
  points,
  matchTime,
  stadium,
  stadiumSector,
  defaultShield
}) => {
  return (
    <Card css={styles.footballcard}>
      <Stack css={styles.footballcard__stack}>
        <CardActionArea
          onClick={() => {
            window.open(FOOTBALL_URL, "_blank");
          }}
          css={styles.footballcard__button}
        >
          <CardContent css={styles.footballcard__content}>
            <Stack direction="row" alignItems="center" justifyContent="space-evenly">
              <CardMedia
                sx={{ height: 80, width: 80 }}
                image={getClubLogo(clubLogo, defaultShield)}
                title={`Escudo do ${club}`}
                css={styles.footballcard__image}
              />

              <X size={32} css={styles.footballcard__x} />

              <CardMedia
                sx={{ height: 80, width: 80 }}
                image={getClubLogo(clubOpponentLogo, defaultShield)}
                title={`Escudo do ${clubOpponent}`}
                css={styles.footballcard__image}
              />
            </Stack>

            <Typography variant="subtitle2" css={styles.footballcard__datetime} gutterBottom>
              {matchDate} às {matchTime}
            </Typography>

            <Typography gutterBottom variant="h6" component="div" css={styles.footballcard__title}>
              {description}
            </Typography>

            <Typography variant="body2" css={styles.footballcard__description}>
              {stadium}
            </Typography>

            <Typography variant="body2" css={styles.footballcard__description}>
              {stadiumSector}
            </Typography>
          </CardContent>

          <CardContent sx={{ py: 0 }}>
            <Stack>
              <Typography variant="body2" css={styles.footballcard__oldprice}>
                De {valueWithoutPoints}
              </Typography>

              <Typography variant="body2" css={styles.footballcard__newprice}>
                Por {valueWithPoints}
                <Typography component="span" css={styles.footballcard__kms}>
                  {" + "}
                  {points} pontos KMV
                </Typography>
              </Typography>
            </Stack>

            <Stack gap={1} py={1} display="flex" flexWrap="wrap" direction="row">
              {tags.map((tag) => (
                <Chip key={tag} label={tag} color="primary" css={styles.footballcard__chip} />
              ))}
            </Stack>
          </CardContent>
        </CardActionArea>

        <CardActions css={styles.footballcard__actions}>
          <Button fullWidth variant="contained" color="warning" sx={{ fontSize: 12 }} href={FOOTBALL_URL} target="_blank">
            IR PARA O SITE
          </Button>
        </CardActions>
      </Stack>
    </Card>
  );
};
